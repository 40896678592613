import React from "react"
import { graphql } from "gatsby"

import Layout from "../templates/layout"
import { Button, Carousel, Column, Heading as H, Hero, PhotoText, Row, Section, Testimonial, Text } from "../components"

export default function IndexPage({ data }) {
  return (
    <Layout>
      <Hero bg={data.hero}></Hero>
      <Section gradient>
        <H as="h2" $center>
          Don’t want to leave your home or office?
        </H>
        <Row>
          <Text $center>
            You don’t have to! We make it easy for you. Zoom calls and DocuSign are available at your convenience.
          </Text>
        </Row>
      </Section>
      <PhotoText image={data.working} imageOn="right" heading="Our Case By Case Method">
        <Text>
          Our firm specializes in getting real results that can help change our client’s lives. With Hicks & Motto, you
          are much more than just a case, you are a person who has a family and a network of people relying on you, and
          you deserve justice. Start your journey towards justice with us today.
        </Text>
      </PhotoText>
      <Section gradient>
        <Row>
          <Column>
            <H as="h2" $center>
              Driven By Results
            </H>
            <Text $center>
              The best way to judge a law firm is to see their list of previous cases. We believe in complete and total
              transparency. Here is a list of some of our recent cases so that you can get an idea of how much you could
              be owed.
            </Text>
          </Column>
        </Row>
        <Row>
          <Column>
            <Carousel />
          </Column>
        </Row>
      </Section>
      <Section maxWidth center>
        <Row>
          <Column>
            <H as="h2" $center>
              Don't Take Our Word For It
            </H>
            <Text $center>Hear from some of our happy and vindicated clients.</Text>
          </Column>
        </Row>
        <Row>
          <Column>
            <Testimonial cite="Ms. Escoria">
              “Mr. James Hicks has been my lawyer for 10 years. He’s always so careful and dedicated to every detail in
              every case that he’s resolved for me with excellent outcomes!”
            </Testimonial>
          </Column>
          <Column>
            <Testimonial cite="Ms. Garvey">
              “I recommend Mr. Hicks because of his approachability and friendliness. His negotiation skills helped
              secure appropriate compensation and helped me regain a part of my life that was in limbo for several
              years.“
            </Testimonial>
          </Column>
          <Column>
            <Testimonial cite="Ms. Telford">
              Mr. James Hicks fought for me and we won $500,000. I received exceptional medical care, and Mr. Hicks and
              his staff handled all the rest. He paid all of my medical bills, and made sure that I was taken care of.”
            </Testimonial>
          </Column>
        </Row>
      </Section>
      <Section gradient>
        <H as="h2" $center>
          Frequently Asked Questions
        </H>
        <Row>
          <Column>
            <Text as="h3" style={{ fontWeight: "bold" }}>
              How much is my case worth?
            </Text>
            <Text style={{ margin: ".5rem 0" }}>
              It depends on your injury and the amount of insurance coverage available
            </Text>
            <Text as="h3" style={{ fontWeight: "bold" }}>
              How much does it cost to pursue my personal injury case?
            </Text>
            <Text style={{ margin: ".5rem 0" }}>
              A personal injury case is prosecuted on a contingency basis, which means there is NO FEE unless we win for
              you.
            </Text>
            <Text as="h3" style={{ fontWeight: "bold" }}>
              If I already hired an attorney, can I fire him/her?
            </Text>
            <Text style={{ margin: ".5rem 0" }}>
              Yes, you can always fire your attorney and hire another attorney to represent you in your personal injury
              case.
            </Text>
            <Text as="h3" style={{ fontWeight: "bold" }}>
              Should I call or give a statement to the other person’s insurance carrier?
            </Text>
            <Text style={{ margin: ".5rem 0" }}>
              NEVER speak with the other person’s insurance carrier. Let your attorney handle those conversations, as
              the insurance adjusters are trained to ask questions to limit your claim.
            </Text>
          </Column>
        </Row>
      </Section>
      <PhotoText image={data.portrait} heading="We're Here To Help" linkText="LET US HELP">
        <Text>Feel free to contact us today with any other questions.</Text>
        <Button type="focus_form">LET US HELP</Button>
      </PhotoText>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    working: file(relativePath: { eq: "working.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    portrait: file(relativePath: { eq: "portrait.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
